import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/SEO"
import NakedList from "../components/NakedList"

const ReleaseItem = styled("li")`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5vh 0;
`

const StyledList = styled(NakedList)`
    margin: 0 auto;
    padding-bottom: 20vh;
`

const ReleasesPage = ({ data }) => {
    const allReleases = data.allReleases.edges
        .map(edge => edge.node.data)
        .map(data => ({
            name: data.Name,
            bandcampURL: data.Bandcamp_URL,
            bandcampEmbedURL: data.Bandcamp_Embed_URL
        }))
    return (
        <React.Fragment>
            <SEO title="Releases" />
            {allReleases && (
                <StyledList>
                    {allReleases.map((release, index) => (
                        <ReleaseItem key={index}>
                            <iframe
                                title={release.name}
                                style={{
                                    border: "0",
                                    width: "350px",
                                    height: "470px"
                                }}
                                src={release.bandcampEmbedURL}
                                seamless
                            ></iframe>
                        </ReleaseItem>
                    ))}
                </StyledList>
            )}
        </React.Fragment>
    )
}

export const query = graphql`
    query GetReleases {
        allReleases: allAirtable(filter: { table: { eq: "Releases" } }) {
            edges {
                node {
                    data {
                        Name
                        Bandcamp_URL
                        Bandcamp_Embed_URL
                    }
                }
            }
        }
    }
`

export default ReleasesPage
